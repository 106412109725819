

@font-face {
     font-family: 'Outfit Semi Bold';
     src: url('https://uploads-ssl.webflow.com/652c6d22e212bf1bb1ccc770/652c6d23e212bf1bb1ccc861_Outfit-SemiBold.ttf') format('truetype');
     font-weight: 600;
     font-style: normal;
     font-display: swap;
}
 @font-face {
     font-family: 'Outfit Regular';
     src: url('https://uploads-ssl.webflow.com/652c6d22e212bf1bb1ccc770/652c6d23e212bf1bb1ccc862_Outfit-Regular.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
     font-display: swap;
}
 @font-face {
     font-family: 'Outfit Light';
     src: url('https://uploads-ssl.webflow.com/652c6d22e212bf1bb1ccc770/652c6d23e212bf1bb1ccc863_Outfit-Light.ttf') format('truetype');
     font-weight: 300;
     font-style: normal;
     font-display: swap;
}
 @font-face {
     font-family: 'Outfit Bold';
     src: url('https://uploads-ssl.webflow.com/652c6d22e212bf1bb1ccc770/652c6d23e212bf1bb1ccc865_Outfit-ExtraBold.ttf') format('truetype');
     font-weight: 700;
     font-style: normal;
     font-display: swap;
}
 @font-face {
     font-family: 'Outfit Medium';
     src: url('https://uploads-ssl.webflow.com/652c6d22e212bf1bb1ccc770/652c6d23e212bf1bb1ccc866_Outfit-Medium.ttf') format('truetype');
     font-weight: 500;
     font-style: normal;
     font-display: swap;
}

.navbar-logo-left{
    z-index:999;
    background-color:#fcfcfc00;
    width:100%;
    margin-bottom:0;
    margin-left:auto;
    margin-right:auto;
    position:fixed;
    top:0
}
.navbar-logo-left-container{
    z-index:5;
    background-color:#0000;
    width:1030px;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    padding:15px 20px
}
.navbar-logo-left-container.shadow-three{
    width:100%;
    margin-bottom:0;
    padding:40px 0 0
}
.navbar-wrapper{
    grid-column-gap:50px;
    justify-content:flex-start;
    align-items:center;
    display:flex
}
.navbar-brand{
    flex:none
}
.nav-menu-wrapper{
    justify-content:space-between;
    align-items:center;
    width:100%;
    display:flex
}
.nav-menu-list{
    grid-column-gap:20px;
    justify-content:space-between;
    align-items:center;
    margin-bottom:0;
    display:flex
}
.nav-link{
    color:var(--paragraph-color-1);
    margin-left:0;
    margin-right:0;
    padding:0;
    font-family:Outfit Medium,sans-serif;
    font-size:18px;
    font-weight:500;
    line-height:1;
    text-decoration:none;
    transition:color .3s;
    display:inline-block
}
.nav-link:hover{
    color:#8e44ec
}
.nav-link:focus-visible{
    color:#8e44ec
}
.nav-link[data-wf-focus-visible]{
    color:#8e44ec
}
.nav-dropdown{
    margin-left:0;
    margin-right:0
}
.nav-dropdown-toggle{
    grid-column-gap:8px;
    letter-spacing:.25px;
    padding:5px 0;
    font-family:Outfit Medium,sans-serif;
    font-size:18px;
    font-weight:500;
    line-height:1;
    transition:color .3s;
    display:flex
}
.nav-dropdown-toggle:hover{
    color:#8e44ec
}
.nav-dropdown-toggle:focus-visible{
    color:#8e44ec
}
.nav-dropdown-toggle[data-wf-focus-visible]{
    color:#8e44ec
}
.nav-dropdown-toggle.landing{
    color:#fff
}
.nav-dropdown-toggle.landing:hover{
    color:#f0f0f0
}
.nav-dropdown-icon{
    margin-right:0;
    font-size:14px;
    position:static
}
.nav-dropdown-list{
    background-color:#0000;
    min-width:200px
}
.nav-dropdown-list.w--open{
    grid-row-gap:10px;
    flex-direction:column
}
.nav-dropdown-link{
    margin-left:0;
    margin-right:0;
    padding:5px 0;
    line-height:1;
    transition:transform .3s,color .3s;
    transform:translate(0)
}
.nav-dropdown-link:hover{
    color:#8e44ec;
    transform:translate(5px)
}
.nav-dropdown-link:focus-visible{
    outline-offset:0px;
    color:#0050bd;
    border-radius:5px;
    outline:2px solid #0050bd
}
.nav-dropdown-link[data-wf-focus-visible]{
    outline-offset:0px;
    color:#0050bd;
    border-radius:5px;
    outline:2px solid #0050bd
}
.nav-dropdown-link.w--current{
    color:#8e44ec
}
.navbar-logo-left-copy {
	z-index: 999;
	background-color: #E0F1EB;
	width: 100%;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	
}
.navbar-wrapper {
	max-width: 1170px;
	margin: 0 auto;
	width: 100%;

  padding: 40px 0 ;
}
.navbar-brand.w-nav-brand img {
	width: 55px;
	max-width: 100%;
	display: inline-block;
	/* font-size: 0; */
	vertical-align: middle;
	border: 0;
}
.w-button {
	color: #fff;
	line-height: inherit;
	cursor: pointer;
	background-color: #3898ec;
	border: 0;
	border-radius: 0;
	padding: 9px 15px;
	text-decoration: none;
	display: inline-block;
}
.w-nav-menu {
	float: right;
	position: relative;
}
.w-nav-button {
	float: right;
	cursor: pointer;
	-webkit-tap-highlight-color: #0000;
	tap-highlight-color: #0000;
	-webkit-user-select: none;
	user-select: none;
	
	display: none;
	position: relative;
}
body {
	color: var(--paragraph-color-1);
	font-family: Outfit Regular,sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.6;
}
.nav-menu-list {
	grid-column-gap: 20px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	display: flex;
}
.nav-link {
	color: #333;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	font-family: Outfit Medium,sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 1;
	text-decoration: none;
	transition: color .3s;
	display: inline-block;
}
.nav-link:hover {
	color: #9450ec;
}
.w-nav-menu {
margin-top: 1px;
}
.w-list-unstyled {
	padding-left: 0;
	list-style: none;margin: 0;
}
.navbar-logo-left-copy .primary-button.small {
	background-color: #0966a7;
	-webkit-text-fill-color: inherit;
	background-clip: border-box;
	padding: 10px 24px;
	text-decoration: none;
	
	display: inline-block;
	box-sizing: border-box;
}
.navbar-logo-left-copy .primary-button {
	border: 1px solid #01001a;
	background-color: var(--blue);
	box-shadow: 3px 3px 0 0 #01001a;
	color: #fff;
	text-align: center;
	border-radius: 5px;
	padding: 15px 37px;
	font-family: Outfit Semi Bold,sans-serif;
	font-size: 19px;
	font-weight: 600;
	line-height: 1.526;
	transition: box-shadow .3s,transform .3s,background-color .3s;
	position: relative;
}
.navbar-logo-left-copy .primary-button:hover {
	background-color: #0966a7;
	box-shadow: 0 0 0 0 #01001a;
	transform: translate(4px,4px);
}
@media screen and (min-width:1280px){
	 .navbar-wrapper{
        grid-column-gap:100px
    }
    .nav-menu-list{
        grid-column-gap:50px
    }
    .nav-menu-list.column-gap-25{
        grid-column-gap:25px
    }
}
@media screen and (max-width:991px){
	.w-nav-button {
		display: block;
	}
	.w-nav-menu {
		display: none;
	}
	.navbar-wrapper {
		max-width: 720px;
	}
	.menu-button {
	border: 2px solid #01001a;
	border-radius: 10px;
	padding: 6px 15px;
	transition: color .3s,background-color .3s;
}

 .navbar-logo-left{
        margin-bottom:-92px
    }
    .navbar-logo-left-container.shadow-three{
        padding:20px 0
    }
    .navbar-wrapper{
        justify-content:space-between
    }
    .nav-menu-wrapper {
	border-top: 3px solid #8e44ec;
	background-color: #fff;
	padding: 20px 20px 40px;
	box-sizing: border-box;
}
#w-nav-overlay-0 .nav-menu-wrapper.w-nav-menu {
	display: block;
	text-align: center;
	background:  #fff;
	background-color: #fff;
	min-width: 200px;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	overflow: visible;
	display: block !important;

}
.navbar-logo-left-copy {
	
	position: relative;
}
    .nav-menu-list{
        grid-row-gap:10px;
        flex-flow:column wrap;
        justify-content:flex-start;
        align-items:flex-start;
        margin-top:0;
        padding:0 20px;
        display:flex
    }
    .nav-menu-list.column-gap-25{
        margin-top:10px
    }
    .nav-link,.nav-dropdown-toggle{
        padding:10px 5px
    }
    .nav-dropdown-toggle.landing{
        color:var(--paragraph-color-1)
    }
    .nav-dropdown-toggle.landing:hover{
        color:#8e44ec;
    }
	.menu-button:hover span,.active-div .w-icon-nav-menu span{
       background-color:#fff
    }
	.active-div {
		background-color: #8e44ec;
		color: #fff;
	}
    .nav-dropdown-icon{
        margin-left:0
    }
    .nav-dropdown-list{
        position:relative
    }
    
    .menu-button:hover{
        background-color:#8e44ec;
        color:#fff
    }
    .menu-button.w--open{
        background-color:#8e44ec;
        color:#fff;
        border-radius:10px
    }
	.w-icon-nav-menu {
	  display: inline-block;
	  cursor: pointer;
	}

	.w-icon-nav-menu span {
		display: block;
		width: 13px;
		height: 2px;
		margin: 2px 0;
		background-color: #333;
		transition: 0.3s;
	}
	.menu-button {
		
		box-sizing: border-box;
		height: 46.2px;
		width: 46.2px;
	}
	/* Optional: Adding a hover effect */
	.w-icon-nav-menu:hover span {
	  background-color: #666;
	}
}
@media screen and (max-width: 767px) {
  .navbar-wrapper {
    max-width: 100%;padding: 20px 10px;
  }
}
.menu-button {
	
	margin: 0;
}