:root {
  --primary-background: #FAF9F6;
  --secondary-background: #ffffff;
  --primary: #20688d;
  /* --secondary: ; */
  --contrast: #292F36;
}

html,
.app,
.root {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: var(--primary-background);
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  box-sizing: border-box;
}

.menu-button {
  margin-right: 2rem;
}

.navbar {
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23)
}

.navbar-menu {
  margin-left: auto;
}

.navbar-menu ul {
  display: flex;
  padding: 0;
}

.navbar-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navbar-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.navbar-link {
  text-decoration: none;
  color: black;
}

.navbar-link img {
  display: block;
  margin: auto;
}

.navbar-link p {
  margin: 0;
}

.home-button {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-left: 3.5rem;
}

.home-button img {
  width: 2em;
  margin-right: 0.5em;
}

/* interface */
.app-interface {
  overflow: hidden;
  display: flex;
  background-color: white;
}

#sidebar {
  margin: 0px;
  padding: 4vh 3.5rem;

  background-color: var(--secondary-background);
  min-width: 20rem;
  max-width: 20rem;

  overflow-y: auto;

  /* holy shit I've never spent so long on a css bug */
  height: 84vh;
}


.text-box {
  width: 100%;
}

.submit-button {
  margin: auto;
  padding: 1em 2em;
  display: block;

  border: none;
  /* border-radius: 20px; */
  background-color: var(--primary);
  color: #ffffff;
}

.form-title {
  font-size: 1.5em;
  color: var(--primary)
}

.package-preview {
  border: 1px solid black;
  display: block;
  margin: 1em auto;
  width: 80%;
}

.section-divider {
  color: black;
  margin-top: 2em;
}

.section-info {
  font-size: 0.8em;
}

.price-selection-form {
  display: flex;
  justify-content: space-between;
}

/* Response text */
.success-text {
  color: green;
}

.error-text {
  color: red;
}

/* Order Confirmation page */
.status-message p,
h1 {
  text-align: center;
}

.total-text {
  font-size: 1.1rem;
}

#deliverytracking-wrapper {
  background-color: #FAF9F6;
}

#deliverytracking-map-wrapper {
  display: flex;
  justify-content: center;
  margin: 1vh 0;
}

#tracking-info {
  display: flex;
  justify-content: center;
}

.tracking-box-wrapper {
  font-weight: bold;
  padding: 0;
  margin: 0 2vw;
  width: 27%;
}

.tracking-box {
  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  font-weight: normal;
  padding: 2rem;
  /* background-color: #20688D; */
  color: black;
  border-radius: 3px;
  font-size: 1rem;
}

.tracking-box-element {
  /* display: flex; */
  /* justify-content: space-between; */
  /* margin: 0.5rem 0; */
  /* margin: auto; */
  text-align: left;
}

.tracking-box-title {
  /* margin: 1.7rem 3rem 0 2.5rem; */
  /* margin: 0 8vw 0 0rem ; */
  margin-bottom: 0.15rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
}

.tracking-box-text {
  margin: 0 0 1rem 0;
}

#tracking-box-img {
  width: 100px;
  margin: 0 0 0 1vw;
}

.delivery-timeline {
  justify-content: space-evenly;
  background-color: #20688D;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  margin-top: 3rem;
}

.delivery-status {
  padding-top: 6px;
  padding-bottom: 6px;
}

.Delivery-Timeline-Container {
  display: flex;
}

.delivery-timeline-step {
  font-weight: normal;
  text-align: center;
}

.delivery-timeline-step-text {
  margin: 0;
}

.delivery-timeline-step-arrow {
  margin: 0;
  font-size: 2rem;
}

#payment-message {
  margin-top: 1rem;
}

.proof-of-delivery {
  border: 2px solid black;
  width: 100%;
}

.MuiToggleButtonGroup-root {
  width: 100%;
}

.stripe-stamp {
  margin-top: 1rem;
  font-size: 0.9rem;
  display: flex;
}

.stripe-stamp img {
  height: 1.5rem;
  width: auto;
  justify-content: center;
}

.stripe-stamp p {
  margin: auto 0;
  color: rgba(9, 9, 9, 0.5);
}

@media only screen and (max-width: 100vw) {

  /* For monitors: */
  [class="delivery-timeline"] {
    width: 100%;
    padding-left: calc(100vw - 91%);
    padding-right: 1.5%;
  }

  [class="delivery-status"] {
    padding-left: 2%;
    padding-right: 2%;

  }
}

@media only screen and (max-width: 1523px) {

  /* For monitor: */
  [class="delivery-timeline"] {
    width: 100%;
    padding-left: calc(100vw - 95%);
    padding-right: 1.5%;
  }

  [class="delivery-status"] {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
}

@media only screen and (max-width: 1450px) {

  /* For monitor */
  [class="delivery-timeline"] {
    width: 100%;
    padding-left: calc(100vw - 97%);
    padding-right: 1.5%;
  }
}

@media only screen and (max-width: 1413px) {
  /* For monitor */
  [class="delivery-timeline"] {
    width: 100%;
    padding-left: calc(100vw - 92%);
    padding-right: 1.5%;
  }
}

@media only screen and (max-width: 1360px) {
  /* For monitor */
  [class="delivery-timeline"] {
    width: 100%;
    padding-left: calc(100vw - 93%);
    padding-right: 1.5%;
  }
}

@media only screen and (max-width: 1335px) {
  /* For monitor */
  [class="delivery-timeline"] {
    width: 100%;
    padding-left: calc(100vw - 95%);
    padding-right: 1.5%;
  }
}

@media only screen and (max-width: 1241px) {
  /* For monitor */
  [class="delivery-timeline"] {
    width: 100%;
    padding-left: calc(100vw - 88%);
    padding-right: 1.5%;
  }
}

@media only screen and (max-width: 1149px) {

  /* For monitor */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 95%);
  }

  [class="delivery-status"] {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media only screen and (max-width: 1119px) {

  /* For laptops: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 92%);
  }

  [class="delivery-status"] {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
}

@media only screen and (max-width: 1073px) {

  /* For laptops: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 86%);
  }
}

@media only screen and (max-width: 1000px) {

  /* For laptops: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 90%);
  }
}

@media only screen and (max-width: 886px) {

  /* For laptops: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 93%);
  }
}

@media only screen and (max-width: 811px) {

  /* For laptops: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 88%);
  }
}

@media only screen and (max-width: 760px) {

  /* For laptops: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 90%);
  }
}

@media only screen and (max-width: 730px) {

  /* For laptops: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 92%);
  }
}

@media only screen and (max-width: 688px) {

  /* For ipads: */
  [class="delivery-status"] {
    font-size: 16px;
  }

  [class="delivery-timeline-step-arrow"] {
    font-size: 16px;
  }

  [class="delivery-timeline"] {
    padding-left: calc(100vw - 88%);
  }

  [class="delivery-status"] {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }

  [class="tracking-box-title"] {
    font-size: 16px;
  }

  [class="tracking-box-text"] {
    font-size: 12px;
  }

  [class="tracking-box-wrapper"] {
    width: 32%;
  }
}

@media only screen and (max-width: 656px) {

  /* For ipads: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 90%);
  }
}

@media only screen and (max-width: 622px) {

  /* For ipads: */
  [class="delivery-status"] {
    font-size: 14px;
  }

  [class="delivery-timeline-step-arrow"] {
    font-size: 14px;
  }
}

@media only screen and (max-width: 563px) {

  /* For ipads: */
  [class="delivery-status"] {
    font-size: 12px;
  }

  [class="delivery-timeline-step-arrow"] {
    font-size: 12px;
  }

  [class="delivery-timeline"] {
    padding-left: calc(100vw - 86%);
  }

  [class="tracking-box-title"] {
    font-size: 14px;
  }

  [class="tracking-box-text"] {
    font-size: 11px;
  }

  [class="tracking-box-wrapper"] {
    width: 32%;
  }
}

@media only screen and (max-width: 533px) {

  /* For ipads: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 86%);
  }
}

@media only screen and (max-width: 520px) {

  /* For ipads: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 90%);
  }
}

@media only screen and (max-width: 474px) {

  /* For ipads: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 92%);
  }
}

@media only screen and (max-width: 453px) {

  /* For mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 88%);
  }

  [class="delivery-status"] {
    font-size: 10px;
  }

  [class*="delivery-timeline-step-arrow"] {
    font-size: 10px;
  }
}

@media only screen and (max-width: 418px) {

  /* For mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 90%);
  }
}

@media only screen and (max-width: 390px) {

  /* For mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 92%);
  }
}

@media only screen and (max-width: 375px) {

  /* For small mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 82%);
  }
}

@media only screen and (max-width: 353px) {

  /* For small mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 84%);
  }
}

@media only screen and (max-width: 341px) {

  /* For small mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 86%);
  }
}

@media only screen and (max-width: 324px) {

  /* For small mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 87%);
  }
}

@media only screen and (max-width: 310px) {

  /* For small mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 88%);
  }
}

@media only screen and (max-width: 300px) {

  /* For small mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 90%);
  }
}

@media only screen and (max-width: 285px) {

  /* For small mobile phones: */
  [class="delivery-timeline"] {
    padding-left: calc(100vw - 91%);
  }
}